<template lang="pug">
  .home
    section.home__welcome
      carousel(
        :perPage="1"
        :loop="true"
        :navigationEnabled="true"
        navigationNextLabel=""
        navigationPrevLabel=""
        autoplay
        :autoplayTimeout="4000"
      ).home__welcome-carousel
        slide(
          v-for="(slide, index) of categories"
          :key="slide.head + index"
          ref="slide"
        ).home__welcome-carousel__item
          .container.home__welcome-carousel__container
            .home__welcome-carousel__content.col-lg-6.col-md-6.col-sm-6.col-xs-6
              h3.home__welcome-carousel__subtitle(v-html="slide.head")
              h2.home__welcome-carousel__title(v-html="slide.title")
              p.home__welcome-carousel__description(v-html="slide.description")
              router-link(
                :to=`$i18nRoute({
                    name: 'ProductsCategory',
                    params: {
                        category: slide.name.toLowerCase(),
                    }
                })`
                v-html="$t('pages.home.welcome.link')"
              ).home__welcome-carousel__link

          img(:src="slide.img")
    section.home__services
      .container
        .row.home__services-row
          .home__services-list.col-lg-6.col-md-12
            .home__services-item.col-sm-6.col-xs-6
              .home__services-item__content
                .home__services-item__image
                  img(:src="require('@/assets/images/24-hours-support.png')")
                h3.home__services-item__title(v-html="$t('pages.home.services.support.title')")
                p.home__services-item__description(v-html="$t('pages.home.services.support.description')")

            .home__services-item.col-sm-6.col-xs-6
              .home__services-item__content
                .home__services-item__image
                  img(:src="require('@/assets/images/tracking-number.png')")
                h3.home__services-item__title(v-html="$t('pages.home.services.check.title')")
                p.home__services-item__description(v-html="$t('pages.home.services.check.description')")

            .home__services-item.col-sm-6.col-xs-6
              .home__services-item__content
                .home__services-item__image
                  img(:src="require('@/assets/images/handshake.png')")
                h3.home__services-item__title(v-html="$t('pages.home.services.cooperation.title')")
                p.home__services-item__description(v-html="$t('pages.home.services.cooperation.description')")

            .home__services-item.col-sm-6.col-xs-6
              .home__services-item__content
                .home__services-item__image
                  img(:src="require('@/assets/images/shuffle-arrows.png')")
                h3.home__services-item__title(v-html="$t('pages.home.services.policy.title')")
                p.home__services-item__description(v-html="$t('pages.home.services.policy.description')")

          .home__services-content.col-lg-6.col-md-12
            h2.title_2.home__services-title(v-html="$t('pages.home.services.title')")
            p.home__services-description(v-html="$t('pages.home.services.description')")
            router-link(:to="{name: 'About'}" v-html="$t('pages.home.services.link')").home__services-link
    section.home__why
      .container
        .row.home__why-row
          .col-lg-6.col-md-6.col-sm-12.col-xs-12
            .home__why-content
              h2.title_2.home__why-title(v-html="$t('pages.home.why.title')")

              .row.home__why-list
                .col-lg-12.col-md-12.home__why-item
                  .home__why-item__content
                    .home__why-item__image
                      img(:src="require('@/assets/images/price.png')")
                    .home__why-item__block
                      h3.home__why-item__title(v-html="$t('pages.home.why.list.item_1.title')")
                      .home__why-item__description(v-html="$t('pages.home.why.list.item_1.description')")

                .col-lg-12.col-md-12.home__why-item
                  .home__why-item__content
                    .home__why-item__image
                      img(:src="require('@/assets/images/certified.png')")
                    .home__why-item__block
                      h3.home__why-item__title(v-html="$t('pages.home.why.list.item_2.title')")
                      .home__why-item__description(v-html="$t('pages.home.why.list.item_2.description')")

                .col-lg-12.col-md-12.home__why-item
                  .home__why-item__content
                    .home__why-item__image
                      img(:src="require('@/assets/images/customer.png')")
                    .home__why-item__block
                      h3.home__why-item__title(v-html="$t('pages.home.why.list.item_3.title')")
                      .home__why-item__description(v-html="$t('pages.home.why.list.item_3.description')")

    section.home__menu
      .home__menu-carousel
        router-link(:to="$i18nRoute({ name: 'ProductsCategory', params: {category: 'injection'}})").home__menu-carousel__item
          .home__menu-carousel__block
            .home__menu-carousel__image
              img(:src="require('@/assets/images/injection.png')")
          h3.home__menu-carousel__title(v-html="$t('pages.home.menu.item_1')")

        router-link(:to="$i18nRoute({ name: 'ProductsCategory', params: {category: 'oral'}})").home__menu-carousel__item
          .home__menu-carousel__block
            .home__menu-carousel__image
              img(:src="require('@/assets/images/oral.png')")
          h3.home__menu-carousel__title(v-html="$t('pages.home.menu.item_2')")

        router-link(:to="$i18nRoute({ name: 'About'})").home__menu-carousel__item
          .home__menu-carousel__block
            .home__menu-carousel__image
              img(:src="require('@/assets/images/microscopes.png')")
          h3.home__menu-carousel__title(v-html="$t('pages.home.menu.item_3')")

        router-link(:to="$i18nRoute({ name: 'News'})").home__menu-carousel__item
          .home__menu-carousel__block
            .home__menu-carousel__image.home__menu-carousel__image-news
              img(:src="require('@/assets/images/news.png')")
          h3.home__menu-carousel__title(v-html="$t('pages.home.menu.item_4')")
    section.home__news
      .container
        h2.title_2.home__news-title(v-html="$t('pages.home.news.title')")

        carousel(
          :perPageCustom="[[300, 1], [550, 2], [991, 3]]"
          :paginationEnabled="false"
          :navigationEnabled="true"
          navigationNextLabel=""
          navigationPrevLabel=""
          :loop="true"
        ).home__news-carousel
          slide(v-for="(slide, index) in news" :key="'slide-' + index")
            NewsItem(:news="slide")
    section.home__join
      .container.home__join-container
        h2.title_2.home__join-title(v-html="$t('pages.home.join.title')")
        p.home__join-description(v-html="$t('pages.home.join.description')")
        form.contact__form
          .row.form__group
            .col-6.col-xs-12
              CustomInput(
                :placeholder="$t('form.name')"
                v-model="form.name"
              )
            .col-6.col-xs-12
              CustomInput(
                :placeholder="$t('form.email')"
                v-model="form.email"
              )
          .row.form__group
            .col-12
              CustomTextarea(
                :placeholder="$t('form.message')"
                v-model="form.message"
              )

          .row.form__group
            .col-12
              button(@click.prevent="send" v-html="$t('form.submit')").contact__form-button
</template>

<script>
import BaseIcon from '@/components/common/BaseIcon'
import CustomInput from '@/components/common/CustomInput'
import CustomTextarea from '@/components/common/CustomTextarea'
import BaseImage from '@/components/common/BaseImage'
import NewsItem from '@/components/common/NewsItem'
import { Carousel, Slide } from 'vue-carousel'
import { mapState } from 'vuex'
import NewsService from '@/services/news.service'
import FeedbackService from '@/services/feedback.service'

export default {
  name: 'Index',
  components: { NewsItem, BaseImage, CustomInput, CustomTextarea, BaseIcon, Carousel, Slide },
  data: () => ({
    form: {
      name: '',
      email: '',
      message: ''
    },
    news_options: {
      limit: 3,
      offset: 0
    }
  }),
  computed: {
    ...mapState({
      news: state => state.news.news,
      categories: state => state.categories.categories,
      categories_loaded: state => state.categories.categories_loaded
    })
  },
  updated () {
    if (this.categories_loaded) {
      this.$refs.slide[0].$el.classList.add('VueCarousel-slide-active')
    }
  },
  methods: {
    async getNews () {
      await NewsService.getNews({ ...this.news_options })
          .then(response => this.$store.commit('news/setNews', response.news))
    },
    async send () {
      await FeedbackService.sendMessage(this.form)
          .then(response => console.log(response))
    }
  },
  mounted () {
    this.getNews()
  }
}
</script>
